import React from 'react'

export default function Footer(props) {
    const currentYear = () => {
        return new Date().getFullYear();
    }
    return (

        <div className="container footer-credit">
            <hr />

            <div className={`mb-3 text-center text-${props.mode === 'light' ? 'dark' : 'light'}`}>
                <small style={{ color: props.mode === 'light' ? "grey" : 'grey' }}>&#169; {currentYear()} by <span className='fw-semibold'>TEXT CASES</span> | Created by <a href="https://www.techspecsmart.com"> <span className='fw-semibold' style={{ color: props.mode === 'light' ? "rgb(242, 97, 1)" : 'grey' }}>Naveen Gupta</span></a></small>
            </div>
        </div>

    )
}
