import React from 'react'
import { Link } from 'react-router-dom'

export default function Error(props) {

    // let myStyle = {
    //     fontSize: 80, color: props.mode === 'light' ? '#f26101' : 'white',
    // }
    
    return (
        <div className={`container text-center my-5 text-${props.mode === 'light' ? 'dark' : 'light'}`} >
            <div className="row justify-content-center align-items-center">
                <div className="col-md-8">
                    <h1 style={{fontSize: 70, color: props.mode === 'light' ? '#f26101' : 'white',}} ><i class="fa-solid fa-plug-circle-xmark"></i></h1>
                    <h1 className='fw-bold my-3'>404 Error Page</h1>

                    <h4 className='fw-semibold my-4'><i class="fa-solid fa-face-sad-tear"></i> Sorry, This Page does not Exist.</h4>
                    <Link to="/" className={`btn btn-${props.mode === 'light' ? 'light' : 'dark'} my-4  rounded-1`}><i class="fa-solid fa-angles-left"></i> Back To Home</Link>
                </div>
            </div>
        </div>
    )
}
