// import React, { useState } from 'react'

export default function About(props) {
    let myStyle = {
        color: props.mode === 'light' ? '#343a40' : 'white',
        backgroundColor: props.mode === 'dark' ? '#343a40' : 'white',

    }

    return (
        <div className={`container my-3 text-${props.mode === 'light' ? 'dark' : 'light'}`} >
            <div className="row justify-content-center">
                <div className='col-md-10'>
                    <h1 className="fw-bold mb-3" style={{color: props.mode === 'light'? '#f26101':'white' }}>About Us</h1>
                    <p>Welcome to <span className="fw-semibold">"TEXT CASES"</span>, the online text utility tool that makes it easy to convert and clean your text. With <span className="fw-semibold">"TEXT CASES"</span>, you can easily convert text to lowercase, uppercase, or proper case, as well as count the number of words and characters. Our tool also allows you to quickly remove extra spaces from paragraphs, making it easy to tidy up and format your writing.</p>
                    <p>Our team is dedicated to providing a fast and user-friendly tool that can help you with a variety of text-related tasks. Whether you're working on a personal project or a professional document, <span className="fw-semibold">"TEXT CASES"</span> has the features you need to get the job done.</p>
                    <p>We hope you find <span className="fw-semibold">"TEXT CASES"</span> helpful and easy to use. If you have any questions or feedback, please don't hesitate to contact us. We're always looking for ways to improve and make our tool even more useful to our users.</p>
                    <h2 className="fw-bold my-4" style={{color: props.mode === 'light'? '#f26101':'white' }}>Know About Some Key Points</h2>
                    <div className="accordion mb-5" id="accordionExample" >
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button fw-semibold" type="button" style={myStyle} data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Free To Use Tool
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" style={myStyle} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body" >
                                    <p>At <span className="fw-semibold">"TEXT CASES"</span>, we believe that everyone should have access to a high-quality text utility tool. That's why we've made our service completely free to use. Whether you're a student, a professional, or anyone else in need of a reliable text converter and cleaner, you can use <span className="fw-semibold">"TEXT CASES"</span> at no cost.</p>
                                    <p>With <span className="fw-semibold">"TEXT CASES"</span>, you can easily convert text to lowercase, uppercase, or proper case, as well as count the number of words and characters. Our tool also allows you to quickly remove extra spaces from paragraphs, making it easy to tidy up and format your writing. And best of all, it's all completely free.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed fw-semibold" style={myStyle} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Analyze Your Text
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" style={myStyle} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body" >
                                    <p><span className="fw-semibold">"TEXT CASES"</span> is more than just a simple text converter and cleaner - it's also a powerful tool for analyzing your text. With our word and character count features, you can easily get a sense of the length and complexity of your writing. This can be especially useful when you're working on a specific project that has specific requirements for word count or character count.</p>
                                    <p>In addition to providing a simple count of words and characters, <span className="fw-semibold">"TEXT CASES"</span> also allows you to see a breakdown of the most common words used in your text. This can help you identify trends and patterns in your writing, and can be a useful tool for identifying areas where you might need to add more variety to your word choice.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed fw-semibold" type="button" style={myStyle} data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Browser Compatibility
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" style={myStyle} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body" >
                                    <p>At <span className="fw-semibold">"TEXT CASES"</span>, we understand the importance of being able to use our text utility tool from any device and any web browser. That's why we've designed our tool to be fully compatible with all major web browsers, including Google Chrome, Mozilla Firefox, Safari, and Microsoft Edge.</p>
                                    <p>Whether you're using a desktop computer, a laptop, a tablet, or a smartphone, you can access <span className="fw-semibold">"TEXT CASES"</span> and use all of its features without any issues. We've tested our tool extensively to ensure that it performs well and looks great on all modern devices and browsers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
