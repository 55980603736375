// type "rfc" and HIT ENTER to get react based function template

// import { element } from 'prop-types';

import React, { useState } from 'react';

export default function TextForm(props) {


    const upperCase = () => {
        // console.log("Uppercase button was clicked");
        let newText = text.toUpperCase();
        setText(newText);
        // props.showAlert('Converted to UPPERCASE!', 'success');
    }
    const lowerCase = () => {
        // console.log("Uppercase button was clicked");
        let newText = text.toLowerCase();
        setText(newText);
        // props.showAlert('Converted to Lowercase!', 'success');
    }
    const capitalzeText = () => {

        //method 1 to convert text to capitalize
        // const words = text.split(" ");
        // for (let i = 0; i < words.length; i++) {
        //     words[i] = words[i][0].toUpperCase() + words[i].slice(1);
        // }
        // let newText = words.join(" ");
        //setText(newText);

        //method 2 to convert text to capitalize
        let text1 = text.toLowerCase();
        let newText = text1.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()); // one liner solution
        setText(newText);
        // props.showAlert('Converted to Capitalize Text!', 'success');
    }
    const urlCase = () => {
        // console.log("Uppercase button was clicked");
        let newText1 = text.toLowerCase();
        let newText2 = newText1.replaceAll(' ', '-');
        setText(newText2);
        // props.showAlert('Converted to URL Case!', 'success');
    }

    const removeSpace = () => {
        // console.log("Uppercase button was clicked");
        let newText = text.split(/[ ]+/);
        setText(newText.join(" "));
        // props.showAlert('Extra Spaces Removed!', 'success');
    }
    const copyText = () => {
        // let copyText = document.getElementById("myBox");
        // copyText.select();
        // navigator.clipboard.writeText(copyText.value);
        // document.getSelection().removeAllRanges();
        navigator.clipboard.writeText(text);
        props.showAlert('Text copied to Clipboard!', 'success');
    }
    const clear = () => {
        let newText = '';
        setText(newText);
        props.showAlert('Text Box Cleared!', 'success');
    }
    const handleOnChange = (event) => {
        // console.log("Handle ON Change");
        setText(event.target.value);
    }
    const [text, setText] = useState('');

    return (

        <>
            <div className="container">

                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className={`my-3 text-${props.mode === 'light' ? 'dark' : 'light'}`}>

                            <h1 className="text-center text-uppercase mt-4 fw-bold" style={{ color: props.mode === 'light' ? '#f26101' : 'white' }}>{props.heading}</h1>
                            {/* <p className='text-center mb-4'>Forget your <b>Typing Text Case Mistake</b>, Convert your Text in Just <b>ONE CLICK</b></p> */}
                            <p className='text-center mb-4'> The Ultimate Text Utility Tool - <b>"TEXT CASES"</b>. The Fastest way to Transform your Writing.</p>
                            <div className="mb-3">
                                <textarea className="form-control rounded-1" id="myBox" onChange={handleOnChange} rows="8" placeholder="Enter Your Text Here" value={text} style={{ backgroundColor: props.mode === 'dark' ? 'rgb(75 81 88)' : 'white', color: props.mode === 'dark' ? 'white' : 'black' }}></textarea>

                                <button disabled={text.length === 0} className={`btn btn-${props.mode === 'light' ? 'light' : 'dark'} mt-2 btn-sm me-2 rounded-0`} onClick={upperCase}>Convert to UPPERCASE</button>
                                <button disabled={text.length === 0} className={`btn btn-${props.mode === 'light' ? 'light' : 'dark'} mt-2 btn-sm me-2 rounded-0`} onClick={lowerCase}>Convert to lowercase</button>
                                <button disabled={text.length === 0} className={`btn btn-${props.mode === 'light' ? 'light' : 'dark'} mt-2 btn-sm me-2 rounded-0`} onClick={capitalzeText}>Convert to Capitalize</button>
                                <button disabled={text.length === 0} className={`btn btn-${props.mode === 'light' ? 'light' : 'dark'} mt-2 btn-sm me-2 rounded-0`} onClick={urlCase}>Convert to URL Case</button>
                                <button disabled={text.length === 0} className={`btn btn-${props.mode === 'light' ? 'light' : 'dark'} mt-2 btn-sm me-2 rounded-0`} onClick={removeSpace}>Remove Extra Spaces</button>
                                <button disabled={text.length === 0} className={`btn btn-${props.mode === 'light' ? 'light' : 'dark'} mt-2 btn-sm me-2 rounded-0`} onClick={copyText}>Copy Text</button>
                                <button disabled={text.length === 0} className={`btn btn-${props.mode === 'light' ? 'light' : 'dark'} mt-2 btn-sm me-2 rounded-0`} onClick={clear}>Clear Text</button>

                            </div>

                        </div>
                        <div className={`mt-4 mb-5 text-${props.mode === 'light' ? 'dark' : 'light'}`}>
                            <h4 className='fw-bold'>Text Summary</h4>
                            <p>Total Words : <span className='fw-semibold'>{text.split(/\s+/).filter((element) => { return element.length !== 0 }).length}</span> | Total Characters : <span className='fw-semibold'>{text.length}</span> | <span className='fw-bold'>{0.008 * text.split(/\s+/).filter((element) => { return element.length !== 0 }).length}</span> Minutes Read</p>

                            {/* <h4 className='fw-bold'>Preview</h4>
                <p>{text.length > 0 ? text : 'Nothing to Preview'}</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



